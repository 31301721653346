import * as React from "react";
import { FirebaseContext } from "../services";
import { navigate } from "gatsby";

interface AdminRouteProps {
  path: string;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children, path }) => {
  const { authToken, firebase } = React.useContext(FirebaseContext);
  const [isLoaded, setIsLoaded] = React.useState(false);

  if (
    !authToken &&
    typeof window === "object" &&
    window.location.href !== path
  ) {
    navigate(path);
    return null;
  }

  // This isn't synced up with the FirebaseContextProvider
  // TODO: Only define this logic once and handle the promise more nicely
  if (typeof window === "object" && firebase) {
    // TODO: clean this up
    firebase.auth().onAuthStateChanged(function (user) {
      if (
        user &&
        user.email &&
        (user.email.endsWith("@afterword.com") ||
          user.email.endsWith("@afterword.co"))
      ) {
        setIsLoaded(true);
      } else {
        navigate(path);
        return null;
      }
    });
  }

  return <>{(isLoaded && children) || "Loading..."}</>;
};

export default AdminRoute;
