import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout-main";
import AdminRoute from "../../components/AdminRoute";
import Routes from "../../constants/routes";
import { FirebaseContext } from "../../services";
import {
  maybeFormatDate,
  convertTimestampToUtcDate,
} from "../../components/dates";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

const AdminPage = ({ data, location }) => {
  const pages = data.allSitePage.edges;
  const { db, isInitialized } = React.useContext(FirebaseContext);
  const [firebasePages, setFirebasePages] = React.useState([]);

  React.useEffect(() => {
    if (!isInitialized) {
      return;
    }

    let humans = [];
    db.collection("humans")
      .where("isPublished", "==", true)
      .orderBy("firstName")
      .get()
      .then(function (result) {
        // querySnapshot
        if (result.forEach && typeof result.forEach === "function") {
          result.forEach(function (doc) {
            humans.push(doc.data());
          });
        }
        setFirebasePages(humans);
      });
  }, [db, isInitialized]);

  return (
    // Make this an App layout
    <Layout
      location={location}
      pageTitle="Admin"
      hideFooter={true}
      hideLinks={true}
      hideSubscribe={true}
    >
      <AdminRoute path={Routes.LOGIN}>
        <section className="aw-accent">
          <div className="aw-content">
            <h1>Admin</h1>

            <Grid columns={"2fr 1fr"}>
              <div>
                <h2>Firebase Pages</h2>
                {firebasePages.map((human) => {
                  return (
                    <div key={human.pageSlug}>
                      <a href={`/stories/${human.pageSlug}`}>
                        /stories/{human.pageSlug}
                        {human.memorialDatetime
                          ? " - " +
                            maybeFormatDate(
                              convertTimestampToUtcDate(human.memorialDatetime),
                              "ddd MMM D, YYYY"
                            )
                          : ""}
                      </a>
                      {human.zoomSettings?.url && (
                        <>
                          {" "}
                          - <a href={human.zoomSettings.url}>Zoom</a>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              <div>
                <h2>Static Pages</h2>
                {pages.map((page) => {
                  return (
                    <div key={page.node.path}>
                      <a href={page.node.path}>{page.node.path}</a>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </div>
        </section>
      </AdminRoute>
    </Layout>
  );
};

export default AdminPage;

export const pageQuery = graphql`
  query MyQuery {
    allSitePage(filter: { path: { regex: "/(^/stories/)|(^/r/)/" } }) {
      edges {
        node {
          path
        }
      }
    }
  }
`;
